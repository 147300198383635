import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

import { Button } from "Components";
import { getGames } from "Redux/Actions/common";
import { fetchGameLink } from "Redux/Actions/game";
import { ROUTES } from "Routes/constants";
import { openLoginModal } from "Redux/Actions/user";
import { GAME_PLAY_MODES } from "Shared/Constants";
import "./style.scss";


const Categories = ({ }) => {

  const { t } = useTranslation();

  const token = useSelector((state) => state.auth.token);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [listData, setListData] = useState([]);
  const [limit, setLimit] = useState(12);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const fetchGames = (pageNo = 0, isClearPage = false) => {
    let payload = {
      skip: pageNo,
      limit: limit,
    };
    dispatch(
      getGames(payload, (response) => {
        setTotalCount(response?.totalCount || 0);
        if (isClearPage) {
          setListData(response?.games || []);
          if (response?.totalCount == 0 || response?.totalCount <= limit) {
            setPageNo(0);
          } else {
            setPageNo(1);
          }
        } else if (response?.totalCount == 0 || response?.totalCount <= limit) {
          setListData(response?.games);
          setPageNo(0);
        } else {
          setListData((prev) => [...prev, ...response?.games]);
          setPageNo((prev) => prev + 1);
        }
      })
    );
  };


  useEffect(() => {
    window.scrollTo(0, 0);

    let payload = {
      skip: 0,
      limit: limit,
    };
    dispatch(
      getGames(payload, (response) => {
        setListData(response?.games || []);
        setTotalCount(response?.totalCount || 0);
        if (response?.totalCount == 0 || response?.totalCount <= limit) {
          setPageNo(0);
        } else {
          setPageNo(1);
        }
      })
    );
  }, []);

  const playGame = (item) => {
    if (!token) {
      dispatch(openLoginModal(true));
    } else {
      dispatch(fetchGameLink({ id: item._id,playMode : GAME_PLAY_MODES.REAL }, (response) => {
        setTimeout(() => {
          const win = window.open(response, "_blank");
          if (win != null) {
            win.focus();
          }
        }, 0)
      }))
    }
  };

  const handleDemoClick = (item) => {
    dispatch(fetchGameLink({ id: item._id,playMode : GAME_PLAY_MODES.DEMO }, (response) => {
      setTimeout(() => {
        const win = window.open(response, "_blank");
        if (win != null) {
          win.focus();
        }
      }, 0)
    }));
  };

  const seeAll = () => {
    history.push(ROUTES.GAMES);
  };

  return (
    <div className="categories_section">
      <div className="container" >
        {location.pathname == ROUTES.HOME ? <div className="common_title">
          <h2 className="h2">{t("games")}</h2>
        </div> : ""}

        <div className="cate_list_block pt-4">
          <InfiniteScroll
            dataLength={listData.length || 0}
            next={() => fetchGames(pageNo)}
            loader={
              <div className="loader_text">
                <span data-loading-text="Loading..."></span>
              </div>
            }
            hasMore={location.pathname == ROUTES.HOME ? false : totalCount > listData.length}
            style={{ overflow: "hidden" }}
            scrollThreshold={0.7}
          >
            <div className="row">
              {listData?.length ? listData?.map((item, index) => (
                <div key={index} className="col-12 mb-4">
                  <div className="game_box">
                    <figure>
                      <img src={item?.gameImage} alt="game_image" className="img-fluid" />
                    </figure>
                    { item?.isComingSoon ? (
                      <div className="game_card_text text-center">
                        <div className="game_summary d-flex justify-content-center align-items-center flex-column text-center text-white">
                          <h6 className="h5">{item?.gameName}</h6>
                          <span className="h5 text-white">{t("coming-soon")}</span>
                          <p>{t("coming-soon-msg")}</p>
                        </div>
                      </div>
                    ) : (
                    <>
                      {item?.isMaintainence ? (
                        <div className="game_card_text text-center">
                          <div className="game_summary d-flex justify-content-center align-items-center flex-column text-center text-white">
                            <h6 className="h5">{item?.gameName}</h6>
                            <span className="h5 text-white">{t("game-maintenance")}</span>
                            <p>{t("game-maintenance2")}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="game_card_text text-center">
                          <div className="game_summary gap-2 d-flex flex-wrap justify-content-center align-items-center text-center">
                            <h6 className="h5 mb-0">{item?.gameName}</h6>
                            <button type="button" className="btn btn-md btn-red d-flex align-items-center justify-content-center" onClick={() => playGame(item)}>
                              {t("play")}
                            </button>
                            {item?.isDemoAvailable ?
                              <button type="button" className="btn btn-md btn-green d-flex align-items-center justify-content-center" onClick={() => handleDemoClick(item)}>
                                {t("demo")}
                              </button>
                              : ""
                            }
                            {location?.pathname === ROUTES.HOME ? (
                              <button
                                type="button"
                                className="btn btn-md btn-blue d-flex align-items-center justify-content-center"
                                onClick={() => history.push(ROUTES.GAMES)}
                              >
                                {t("more-games")}
                              </button>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  </div>
                </div>
              )) :
                <div className="d-flex align-items-center justify-content-center h6 mb-0 text-center no-data">
                  {t("no-data")}
                </div>
              }
            </div>
          </InfiniteScroll>
        </div>
        {location.pathname == ROUTES.HOME ? (totalCount > 6 ?
          <div className='text-center common-btn'>
            <Button className="btn btn-lg btn-red" onClick={seeAll}>{t("see-all")}</Button>
          </div> : "") : ""
        }

      </div>
    </div>
  );
};

export default Categories;