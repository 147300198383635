import { takeLatest, all, put, takeLeading } from "redux-saga/effects";

import { startLoading, stopLoading } from "Redux/Actions/loader";
import { getRequest, getRequestNoAuth, postRequest } from "Shared/Axios";
import { API_URLS, STATUS_CODES } from "Services/Api/Constants";
import { GET_CATEGORIES, GET_FOOTER_BANKS, GET_GAMES, GET_MAIN_BANNERS, GET_PROMOTIONS, GET_PROVIDERS, GET_SOCIAL_LINKS, GET_WHITE_LABEL_DATA, setProviders, setWhiteLabelData } from "Redux/Actions/common";
import { FETCH_GAME_LINK } from "Redux/Actions/game";
import { logout } from "Redux/Actions/Auth";

function* getCategoriesData({callback = () => { }}) {
  try {
    yield put(startLoading());
    const { data } = yield getRequestNoAuth({
      API: API_URLS.CATEGORIES,
    });
    if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data.data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
    yield put(stopLoading());
  }
}

function* getProviders({ callback = () => { }}) {
  try {
    yield put(startLoading());
    const { data } = yield getRequestNoAuth({
      API: API_URLS.PROVIDERS,
    });
    if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data.data);
      yield put(stopLoading());
      yield put(setProviders(data.data));
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
    yield put(stopLoading());
  }
}

function* getGames({ payload, callback = () => { }}) {
  try {
    yield put(startLoading());
    let apiUrl = `${API_URLS.GAMES}?limit=${payload?.limit}&skip=${payload?.skip}`;

    if(payload?.providerName){
      apiUrl = `${apiUrl}&providerName=${payload.providerName}`;
    }

    if(payload?.categoryId){
      apiUrl = `${apiUrl}&categoryId=${payload.categoryId}`;
    }

    if(payload?.isPopular){
      apiUrl = `${apiUrl}&isPopular=${payload.isPopular}`;
    }

    if(payload?.searchKey){
      apiUrl = `${apiUrl}&searchKey=${payload.searchKey}`;
    }
    const { data } = yield getRequestNoAuth({
      API: apiUrl,
    });
    if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data?.data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
    yield put(stopLoading());
  }
}

function* getPromotions({ payload, callback = () => { }}) {
  try {
    yield put(startLoading());
    let apiUrl = `${API_URLS.PROMOTIONS}?limit=${payload?.limit}&skip=${payload?.skip}`;
    const { data } = yield getRequestNoAuth({
      API: apiUrl,
    });
    if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data?.data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
    yield put(stopLoading());
  }
}

function* getMainBanners({ payload, callback = () => { }}) {
  try {
    console.log("get main banners :"+API_URLS.MAIN_BANNER);
    yield put(startLoading());
    let apiUrl = `${API_URLS.MAIN_BANNER}`;
    const { data } = yield getRequestNoAuth({
      API: apiUrl,
    });
    if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data?.data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
    yield put(stopLoading());
  }
}

function* fetchGameLink({ payload, callback = () => { }}) {
  try {
    console.log("fetch game link here ");
    yield put(startLoading());
    let apiUrl = `${API_URLS.GAMELINK}?gameId=${payload?.id}&playMode=${payload.playMode}`;
    console.log("get link url :  ", apiUrl);
    const apiResponse = yield getRequest({
      API: apiUrl,
    });
    console.log(apiResponse);
    let { data , response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    }else if (data.statusCode == STATUS_CODES.SUCCESS) {
      console.log("run callback ");
      callback(data?.data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
    yield put(stopLoading());
  }
}

function* fetchSocialLinks({ payload, callback = () => { }}) {
  try {
    let apiUrl = `${API_URLS.CONTACTS}`;
    const { data } = yield getRequestNoAuth({
      API: apiUrl,
    });
   if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data?.data);
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
  }
}

function* fetchFooterBanks({ payload, callback = () => { }}) {
  try {
    let apiUrl = `${API_URLS.CMS_BANKS}`;
    const { data } = yield getRequestNoAuth({
      API: apiUrl,
    });
   if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data?.data);
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
  }
}

function* getWhiteLabelData({ callback = () => { }}) {
  try {
    let apiUrl = `${API_URLS.WHITE_LABEL_DATA}`;
    const { data } = yield getRequestNoAuth({
      API: apiUrl,
    });
   if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data?.data);
      yield put(setWhiteLabelData(data?.data?.whiteLabelData || {}));
    } else {
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
  }
}

function* watchCommon() {
  yield all([
     takeLatest(GET_CATEGORIES,getCategoriesData),
     takeLatest(GET_PROVIDERS,getProviders),
     takeLeading(GET_GAMES,getGames),
     takeLatest(GET_PROMOTIONS,getPromotions),
     takeLatest(FETCH_GAME_LINK,fetchGameLink),
     takeLatest(GET_SOCIAL_LINKS,fetchSocialLinks),
     takeLatest(GET_FOOTER_BANKS,fetchFooterBanks),
     takeLatest(GET_MAIN_BANNERS,getMainBanners),
     takeLatest(GET_WHITE_LABEL_DATA,getWhiteLabelData),
    ]);
}

export default watchCommon;
